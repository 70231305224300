@font-face {
  font-family: 'FSLola';
  src: url('./public/Fonts/FSLola/font.woff2') format("woff2"), url('./public/Fonts/FSLola/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-Bold';
  src: url('./public/Fonts/FSLola-Bold/font.woff2') format("woff2"), url('./public/Fonts/FSLola-Bold/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-BoldItalic';
  src: url('./public/Fonts/FSLola-BoldItalic/font.woff2') format("woff2"), url('./public/Fonts/FSLola-BoldItalic/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-ExtraBold';
  src: url('./public/Fonts/FSLola-ExtraBold/font.woff2') format("woff2"), url('./public/Fonts/FSLola-ExtraBold/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-ExtraBoldItalic';
  src: url('./public/Fonts/FSLola-ExtraBoldItalic/font.woff2') format("woff2"), url('./public/Fonts/FSLola-ExtraBoldItalic/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-Bold';
  src: url('./public/Fonts/FSLola-Bold/font.woff2') format("woff2"), url('./public/Fonts/FSLola-Bold/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-Italic';
  src: url('./public/Fonts/FSLola-Italic/font.woff2') format("woff2"), url('./public/Fonts/FSLola-Italic/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-Light';
  src: url('./public/Fonts/FSLola-Light/font.woff2') format("woff2"), url('./public/Fonts/FSLola-Light/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-LightItalic';
  src: url('./public/Fonts/FSLola-LightItalic/font.woff2') format("woff2"), url('./public/Fonts/FSLola-LightItalic/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-Medium';
  src: url('./public/Fonts/FSLola-Medium/font.woff2') format("woff2"), url('./public/Fonts/FSLola-Medium/font.woff') format("woff");
  font-display:swap
}

@font-face {
  font-family: 'FSLola-MediumItalic';
  src: url('./public/Fonts/FSLola-MediumItalic/font.woff2') format("woff2"), url('./public/Fonts/FSLola-MediumItalic/font.woff') format("woff");
  font-display:swap
}

:root {
  /* fonts */
  --font-fslola-medium: "FSLola-Medium";
  --font-fslola-light: "FSLola-Light";
  --font-fslola-normal: "FSLola";
  --font-fslola-bold: "FSLola-Bold";
  --font-fslola-bold-italic: "FSLola-BoldItalic";
  --font-fslola-italic: "FSLola-Italic";

  /* font sizes */
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-normal: 16px;
  --font-size-mobile: 18px;
  --font-size-base: 21.58px;
  --font-size-accordion: 20px;
  --font-size-title: 32.375px;
  --font-size-lg: 53px;

  /* Colors */
  --color-white: #fff;
  --color-indigo: #003e47;
}
